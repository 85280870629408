import React from "react";
import { CheckCircle, Circle, XCircle } from "lucide-react";
import { ShipmentStatus } from "src/api/shipments";
import { parseShipmentStatus } from "src/helpers/shipments";

interface ShipmentProgressBarProps {
  status: ShipmentStatus;
}

const happyFlow: ShipmentStatus[] = [
  ShipmentStatus.Open,
  ShipmentStatus.Closed,
  ShipmentStatus.Sent,
  ShipmentStatus.Confirmed,
  ShipmentStatus.Shipped,
  ShipmentStatus.Delivered,
];

const cancelFlow: ShipmentStatus[] = [
  ShipmentStatus.Open,
  ShipmentStatus.Closed,
  ShipmentStatus.Sent,
  ShipmentStatus.Cancelled,
];

const rejectFlow: ShipmentStatus[] = [
  ShipmentStatus.Open,
  ShipmentStatus.Closed,
  ShipmentStatus.Sent,
  ShipmentStatus.Confirmed,
  ShipmentStatus.Shipped,
  ShipmentStatus.Rejected,
];

const returnFlow: ShipmentStatus[] = [
  ShipmentStatus.Open,
  ShipmentStatus.Closed,
  ShipmentStatus.Sent,
  ShipmentStatus.Confirmed,
  ShipmentStatus.Shipped,
  ShipmentStatus.Returned,
];

const ShipmentProgressBar: React.FC<ShipmentProgressBarProps> = ({
  status,
}) => {
  const flow = happyFlow.includes(status)
    ? happyFlow
    : returnFlow.includes(status)
    ? returnFlow
    : rejectFlow.includes(status)
    ? rejectFlow
    : cancelFlow.includes(status)
    ? cancelFlow
    : happyFlow;

  const getProgressPercentage = () => {
    if (
      status === ShipmentStatus.Cancelled ||
      status === ShipmentStatus.Rejected ||
      status === ShipmentStatus.Returned
    ) {
      return 100;
    }

    const index = flow.indexOf(status);

    if (index === 0) {
      return 2;
    }

    return index !== -1 ? (index / (flow.length - 1)) * 100 : 0;
  };

  const isCompleted = (step: ShipmentStatus) => {
    if (
      flow.indexOf(step) === flow.length - 1 &&
      flow.indexOf(status) === flow.length - 1
    ) {
      return true;
    }

    return flow.indexOf(step) <= flow.indexOf(status);
  };

  const getStatusColor = (step: ShipmentStatus) => {
    return [
      ShipmentStatus.Cancelled,
      ShipmentStatus.Rejected,
      ShipmentStatus.Returned,
    ].includes(step)
      ? "text-danger"
      : isCompleted(step)
      ? "text-success"
      : "text-default";
  };

  const renderIcon = (step: ShipmentStatus) => {
    return [
      ShipmentStatus.Cancelled,
      ShipmentStatus.Rejected,
      ShipmentStatus.Returned,
    ].includes(step) ? (
      <XCircle className="w-6 h-6 text-danger" />
    ) : isCompleted(step) ? (
      <CheckCircle className="w-6 h-6 text-success" />
    ) : (
      <Circle className="w-6 h-6 text-default" />
    );
  };

  const statusColor = [
    ShipmentStatus.Cancelled,
    ShipmentStatus.Rejected,
    ShipmentStatus.Returned,
  ].includes(status)
    ? "-danger"
    : "-success";

  return (
    <div className="w-full max-w-3xl px-1 mx-auto mb-3">
      <div className="relative pt-1">
        <div className="d-flex mb-2 align-items-center justify-content-between">
          <div>
            <span
              className={`text-sm font-semibold inline-block py-1 px-2 uppercase rounded text-white bg${statusColor}`}
            >
              {parseShipmentStatus(status)}
            </span>
          </div>
        </div>
        <div
          className="overflow-hidden h-2 mb-3 mt-3 text-sm d-flex rounded"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          <div
            style={{ width: `${getProgressPercentage()}%` }}
            className={`shadow-none d-flex flex-column text-center whitespace-nowrap text-white justify-content-center ${
              status === ShipmentStatus.Cancelled ||
              status === ShipmentStatus.Rejected ||
              status === ShipmentStatus.Returned
                ? "bg-danger"
                : "bg-success"
            }`}
          ></div>
        </div>
        <div className="d-flex justify-content-between">
          {flow.map((step) => (
            <div key={step} className="d-flex flex-column align-items-center">
              {renderIcon(step)}
              <span className={`mt-2 text-sm ${getStatusColor(step)}`}>
                {parseShipmentStatus(step)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShipmentProgressBar;
