import { gql } from "@apollo/client";
import { PaymentMethod } from "./sales";

export type CheckingAccountMove = {
  id: number;
  accountId: number;
  amount: number;
  paymentMethod: PaymentMethod;
  notes: string;
  createdAt: Date;
};

export type CheckingAccount = {
  id: number;
  negativeMargin: number;

  moves: CheckingAccountMove[];
};

export const GET_ACCOUNT = gql`
  query checkingAccount($id: Int!) {
    data: checkingAccount(id: $id) {
      negativeMargin

      moves {
        id
        amount
        paymentMethod
        notes
        createdAt
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createCheckingAccount($input: CreateCheckingAccountInput!) {
    data: createCheckingAccount(input: $input) {
      id
    }
  }
`;
