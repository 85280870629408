import { useQuery } from "@apollo/client";
import { CCol, CFormLabel, CRow } from "@coreui/react-pro";
import { useFormik } from "formik";
import Api from "src/api";
import { Store, StoreType } from "src/api/stores";
import { logEvent } from "src/helpers/analytics";
import {
  dateFormat,
  defaultDateFormat,
  toArgentinaTime,
} from "src/helpers/dates";
import { GraphQLMeta } from "src/types";
import SmartSelect from "../SmartSelect";
import { sortBy } from "lodash";
import { isAfter } from "date-fns/isAfter";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const RangeAndStorePicker = ({
  refetch,
  maxDate,
  hideRangePicker = false,
  eventTitle,
  defaultDateFrom,
  defaultDateTo,
  defaultLabel,
}: {
  eventTitle: string;
  hideRangePicker?: boolean;
  maxDate?: Date;
  refetch: (variables: any) => void;
  defaultDateFrom?: string;
  defaultDateTo?: string;
  defaultLabel?: string;
}) => {
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          permission: "LIST_SALES",
          type: StoreType.Store,
          limit: 0,
        },
      },
      onCompleted: ({ data }) => {
        if (data.data.length === 1) {
          formik.setFieldValue("storeId", data.data[0].id);
          formik.handleSubmit();
        }
      },
    }
  );

  const formik = useFormik<{
    dateFrom: Date;
    dateTo: Date;
    storeId: number;
    storeName: string;
  }>({
    initialValues: {
      dateFrom: defaultDateFrom ? new Date(defaultDateFrom) : new Date(),
      dateTo: defaultDateTo ? new Date(defaultDateTo) : new Date(),
      storeId: 0,
      storeName: "",
    },
    onSubmit: (data) => {
      let dateFrom = dateFormat(
        data.dateFrom,
        defaultDateFormat,
        toArgentinaTime
      );
      const dateTo = data.dateTo ? dateFormat(data.dateTo) : "";
      const storeName =
        data.storeId > 0
          ? stores?.data.data.find((s) => s.id === data.storeId)?.name ?? ""
          : "";

      if (
        isAfter(
          new Date(dateFrom),
          dateTo !== "" ? new Date(dateTo) : new Date()
        )
      ) {
        dateFrom = dateFormat(
          dateTo === "" ? new Date() : new Date(dateTo),
          defaultDateFormat,
          toArgentinaTime
        );
      }

      logEvent(eventTitle, {
        filters: {
          dateFrom,
          dateTo,
          storeId: data.storeId > 0 ? data.storeId : undefined,
          storeName: data.storeId > 0 ? storeName : undefined,
        },
      });

      refetch({
        variables: {
          dateFrom: hideRangePicker ? dateFormat(new Date()) : dateFrom,
          dateTo: hideRangePicker ? dateFormat(new Date()) : dateTo,
          storeId: data.storeId > 0 ? data.storeId : undefined,
          storeName: data.storeId > 0 ? storeName : undefined,
        },
      });
    },
  });

  return (
    <CRow className="pr-0">
      {!hideRangePicker && (
        <CCol sm={hideRangePicker ? "5" : "4"}>
          <CFormLabel>Rango de Fechas</CFormLabel>
          <DateRangePicker
            calendarIcon={null}
            value={[formik.values.dateFrom, formik.values.dateTo]}
            locale="es-AR"
            format={"dd/MM/yyyy"}
            rangeDivider="hasta"
            id="datepicker"
            maxDate={maxDate ?? new Date()}
            onChange={(date) => {
              logEvent("dashboard.filters.set-date", {
                date,
              });

              if (date) {
                formik.setFieldValue("dateFrom", new Date(date[0]));
                formik.setFieldValue("dateTo", new Date(date[1]));

                formik.handleSubmit();
              } else {
                formik.setFieldValue("dateFrom", "");
                formik.setFieldValue("dateTo", "");
              }
            }}
          />
        </CCol>
      )}

      {(stores?.data.data.length ?? 0) > 1 && (
        <CCol sm={hideRangePicker ? "5" : "3"}>
          <CFormLabel>Punto de Venta</CFormLabel>
          <SmartSelect
            name="storeId"
            search
            options={[
              { value: 0, name: defaultLabel ?? "Todos" },
              ...(sortBy(stores?.data.data ?? [], "name").map((store) => ({
                name: store.name,
                value: store.id,
              })) ?? []),
            ]}
            resultsCount={10}
            placeholder="Selecciona Punto de Venta"
            onChange={(e) => {
              logEvent("dashboard.filters.set-store", {
                storeId: e,
              });

              formik.setFieldValue("storeId", Number(e));
              formik.handleSubmit();
            }}
            value={formik.values.storeId.toString()}
          />
        </CCol>
      )}
    </CRow>
  );
};

export default RangeAndStorePicker;
