import { BatchType } from "src/api/batches";

export const batchTypes = [
  {
    id: 2,
    name: "Ajuste",
    type: BatchType.Count,
    identifier: "COUNT",
  },
  {
    id: 3,
    name: "Devolución",
    type: BatchType.Return,
    identifier: "RETURN",
  },
  {
    id: 4,
    name: "Factura",
    type: BatchType.Invoice,
    identifier: "INVOICE",
  },
  {
    id: 1,
    name: "Remito",
    type: BatchType.Quote,
    identifier: "QUOTE",
  },
  {
    id: 5,
    name: "Nuevo Producto",
    type: BatchType.NewProduct,
    identifier: "NEW_PRODUCT",
  },
  {
    id: 6,
    name: "Movimiento",
    type: BatchType.Move,
    identifier: "MOVE",
  },
  {
    id: 7,
    name: "Compras",
    type: BatchType.Shipment,
    identifier: "SHIPMENT",
  },
];

export const findBatchType = (type: BatchType) =>
  batchTypes.find((bType) => bType.type === type);
