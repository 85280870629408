import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CFormInput,
  CForm,
  CButton,
  CTooltip,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { ExcelFile, Product, importSupplierProducts } from "src/api/products";
import { formatCurrency, isBarcode } from "src/helpers/numbers";
import Icon from "src/components/Icon";
import { useFormik } from "formik";
import {
  Entity,
  GraphQLFind,
  GraphQLMeta,
  Operation,
  SearchForm,
} from "src/types";
import { usePagination } from "src/hooks/pagination";
import Api from "src/api";
import { queryStringToObject } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import { useAdminStore } from "src/store";
import writeXlsxFile from "write-excel-file";
import { useEffect, useRef, useState } from "react";
import PropagateModal, {
  PropagateModalForwardedRef,
} from "src/components/PropagateModal";
import CIcon from "@coreui/icons-react";
import { cilLevelDown, cilLevelUp, cilWarning } from "@coreui/icons";
import BatchChangeModal, {
  BatchChangeModalForwardedRef,
} from "src/components/BatchChangeModal";
import { findPointOfSale } from "src/helpers/stores";
import Pagination from "src/components/Pagination";
import BatchStockModal, {
  BatchStockModalForwardedRef,
} from "src/components/BatchStockModal";
import { StockType } from "src/api/stock";
import AlertDialog, {
  AlertDialogForwardedRef,
} from "src/components/AlertDialog";

const Products = () => {
  const { state, search } = useLocation();
  const { hasPermission, currentCompany } = useAdminStore();
  const alertDialogRef = useRef<AlertDialogForwardedRef>(null);
  const params = useParams();
  const supplierId = Number(params.id);
  const querySearch = { ...queryStringToObject(search), ...state };
  const navigate = useNavigate();
  const canSeeProduct = hasPermission("SHOW_PRODUCT", { force: true });
  const canCreateProduct = hasPermission("CREATE_PRODUCT");
  const canEditProduct = hasPermission("UPDATE_PRODUCT");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const propagateModalRef = useRef<PropagateModalForwardedRef>(null);
  const batchChangeModalRef = useRef<BatchChangeModalForwardedRef>(null);
  const batchChangeStockRef = useRef<BatchStockModalForwardedRef>(null);
  const [productLoading, setProductLoading] = useState<boolean>(false);

  const { data: supplier } = useQuery(Api.Suppliers.GET_SUPPLIER, {
    variables: {
      id: supplierId,
    },
    onError: () => {
      navigate(-1);
    },
  });

  const {
    data: products,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Product>>(Api.Products.LIST_PRODUCTS, {
    variables: {
      fetchPolicy: "no-cache",
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        supplierId,
        search: querySearch.search ?? "",
      },
    },
    onCompleted: ({ data: results }) => {
      if (
        formik.values.search !== "" &&
        results.data.length === 1 &&
        isBarcode(formik.values.search)
      ) {
        logEvent("supplier-products.search.barcode-result", {
          product: results.data,
        });

        resetAndSearch({
          page: 1,
          search: "",
        });

        navigate(`/products/${results.data[0].id}`);
      }
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    `suppliers/${supplierId}/products`,
    refetch,
    { supplierId }
  );
  const { data: excel, loading: downloading } = useQuery<
    GraphQLFind<ExcelFile>
  >(Api.Products.EXPORT_PRODUCTS, {
    fetchPolicy: "no-cache",
    skip: !currentCompany?.id,
    variables: {
      filters: {
        companyId: currentCompany?.id,
        supplierId,
      },
    },
  });

  const downloadFile = () => {
    if (!excel?.data) {
      return;
    }

    const excelData: any = [excel.data.title.slice(0, -1)];

    excel.data.products.forEach((product) => {
      excelData.push([
        {
          value: product.id,
          align: "center",
        },
        {
          value: product.sku,
          align: "center",
        },
        {
          value: product.name,
        },
        {
          value: product.description,
        },
        {
          value: product.barcodes?.map(({ barcode }) => barcode).join("; "),
        },
        {
          value:
            product.suppliers
              .find((s) => s.supplierId === supplierId)
              ?.supplier?.name.toUpperCase() ?? "",
          align: "center",
        },
        {
          value: product.category?.name.toUpperCase() ?? "",
          align: "center",
        },
        {
          value: product.cost,
          align: "right",
        },
        {
          value: product.profit,
          align: "right",
        },
        {
          value: product.tax,
          align: "center",
        },
        {
          value: product.price,
          align: "right",
        },
      ]);
    });

    writeXlsxFile(excelData, {
      fileName: `${excel.data.filename}.xlsx`,
      sheet: "Productos",
    });
  };

  const importFile = async () => {
    if (
      currentCompany &&
      supplierId &&
      fileInputRef.current?.value !== "" &&
      fileInputRef.current?.files?.[0]
    ) {
      setProductLoading(true);
      const formData = new FormData();

      formData.append("file", fileInputRef.current.files[0]);
      formData.append("companyId", currentCompany.id.toString());
      formData.append("supplierId", supplierId.toString());

      try {
        const response = await importSupplierProducts(supplierId, formData);

        if (response) {
          propagateModalRef.current?.open([
            ...(response?.changes?.create ?? []),
            ...(response?.changes?.update ?? []),
            ...(response?.changes?.delete ?? []),
          ]);
        } else {
          alertDialogRef.current?.show(
            "Importar Productos",
            "El archivo que has subido no tiene ninguna diferencia con tu listado de productos actual."
          );
        }
      } catch (e: any) {
        /*
        logEvent("supplier-products.import.error", {
          error: e.message,
          supplierId,
        });

        if (e.message === "invalid_file") {
          alert(
            "El archivo es inválido, por favor revisa que sea el correcto."
          );
        } else {
          alert("No se han podido cargar los productos.");
        }
        */
      } finally {
        // fileInputRef.current.value = "";
        setProductLoading(false);
      }
    }
  };

  const openFilePrompt = () => fileInputRef.current?.click();

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search ?? "",
    },
    onSubmit: (data) => {
      if (!loading) {
        resetAndSearch(
          {
            search: data.search,
          },
          { avoidState: true }
        );
      }
    },
  });

  useEffect(() => {
    resetAndSearch({
      search: formik.values.search,
      page: querySearch.page,
    });

    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={9} className="px-0">
                  Productos del Proveedor "{supplier?.data?.name}"
                </CCol>
                <CCol sm={3} className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CTooltip content={<span>Reducir precios</span>}>
                      <CButton
                        type="button"
                        disabled={products?.data.data.length === 0}
                        onClick={() =>
                          batchChangeModalRef.current?.open(
                            Entity.Supplier,
                            supplierId,
                            Operation.Discount
                          )
                        }
                        color="danger"
                        className="mr-2 p-0 w-10 h-10"
                      >
                        <CIcon
                          size="xl"
                          style={{ verticalAlign: "middle" }}
                          icon={cilLevelDown}
                        />
                      </CButton>
                    </CTooltip>

                    <CTooltip content={<span>Aumentar precios</span>}>
                      <CButton
                        type="button"
                        color="primary"
                        disabled={products?.data.data.length === 0}
                        onClick={() =>
                          batchChangeModalRef.current?.open(
                            Entity.Supplier,
                            supplierId,
                            Operation.Recharge
                          )
                        }
                        className="mr-2 p-0 w-10 h-10"
                      >
                        <CIcon
                          size="xl"
                          style={{ verticalAlign: "middle" }}
                          icon={cilLevelUp}
                        />
                      </CButton>
                    </CTooltip>

                    {currentCompany?.config.stock !== StockType.Unlimited && (
                      <CTooltip content={<span>Alerta de Stock</span>}>
                        <CButton
                          type="button"
                          disabled={products?.data.data.length === 0}
                          onClick={() =>
                            batchChangeStockRef.current?.open(
                              Entity.Supplier,
                              supplierId
                            )
                          }
                          color="warning"
                          className="mr-2 p-0 w-10 h-10"
                        >
                          <CIcon
                            size="xl"
                            style={{ verticalAlign: "middle" }}
                            icon={cilWarning}
                          />
                        </CButton>
                      </CTooltip>
                    )}

                    <CFormInput
                      placeholder="Buscar..."
                      name="search"
                      autoFocus
                      onChange={formik.handleChange}
                      defaultValue={formik.values.search}
                      style={{ flex: 1 }}
                    />

                    {canEditProduct && canCreateProduct && (
                      <CTooltip content={<span>Subir Excel</span>}>
                        <CButton
                          type="button"
                          color="info"
                          className="ml-2 p-0 w-10 h-10"
                          disabled={productLoading || downloading}
                          onClick={openFilePrompt}
                        >
                          <Icon
                            name={
                              productLoading ? "arrow-path" : "arrow-up-tray"
                            }
                            style={{ margin: "0 auto" }}
                          />
                        </CButton>
                      </CTooltip>
                    )}

                    <CTooltip content={<span>Descargar Excel</span>}>
                      <CButton
                        type="button"
                        disabled={
                          products?.data.meta.total === 0 ||
                          productLoading ||
                          downloading
                        }
                        color="success"
                        className="ml-2 p-0 w-10 h-10"
                        onClick={downloadFile}
                      >
                        <Icon
                          name="arrow-down-tray"
                          style={{ margin: "0 auto" }}
                        />
                      </CButton>
                    </CTooltip>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                loading={productLoading}
                itemsPerPage={20}
                items={products?.data.data || []}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "barcode", label: "Código de Barra" },
                  {
                    key: "connections",
                    label: "Conexiones",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "cost",
                    label: "Costo",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "profit",
                    label: "Ganancia (%)",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "price",
                    label: "Precio de Venta",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  cost: (item: Product) => (
                    <td align="right">{formatCurrency(item.cost)}</td>
                  ),
                  profit: (item: Product) => (
                    <td align="right">{item.profit?.toFixed(2)} %</td>
                  ),
                  price: (item: Product) => (
                    <td align="right">{formatCurrency(item.price)}</td>
                  ),
                  connections: (item: Product) => (
                    <td align="center">
                      {item.connections.map((connection, index) => {
                        const currentType = findPointOfSale(
                          connection.priceList.store.type
                        );
                        const StoreIcon = () => currentType?.icon;

                        return (
                          <span
                            key={index}
                            className={
                              item.connections.length > 1 && index > 0
                                ? "ml-1"
                                : ""
                            }
                          >
                            <StoreIcon key={index} />
                          </span>
                        );
                      })}
                    </td>
                  ),
                  barcode: (item: Product) => (
                    <td>
                      {item.barcodes
                        ?.map(({ barcode }) => barcode)
                        .join(", ") ?? ""}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
                clickableRows={canSeeProduct}
                onRowClick={(item) => {
                  if (canSeeProduct) {
                    navigate(`/products/${item.id}`, {
                      state: { ...formik.values, ...querySearch, supplierId },
                    });
                  }
                }}
              />

              <Pagination meta={products} page={page} pageChange={pageChange} />

              <CForm>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={importFile}
                />
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <BatchChangeModal
        onClose={(productIds) => {
          propagateModalRef.current?.open(productIds);
        }}
        ref={batchChangeModalRef}
      />

      <PropagateModal
        title="Importar Productos"
        subtitle="Los productos han sido importados."
        text="¿Quieres propagar estos cambios a alguna de tus listas de precios?"
        ref={propagateModalRef}
      />

      <BatchStockModal ref={batchChangeStockRef} />

      <AlertDialog ref={alertDialogRef} />
    </>
  );
};

export default Products;
