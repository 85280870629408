import { CSmartPaginationProps } from "@coreui/react-pro/dist/esm/components/smart-pagination/CSmartPagination";
import { Pagination } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { objectToQueryString } from "src/helpers/strings";
import { Generic } from "src/types";

type PaginationProps = {
  currentPage: number;
  pageChange: (page: number) => void;
  page: number;
  params?: Generic;
  setPage: (page: number) => void;
  resetAndSearch: (
    values: Generic,
    args?: { force?: boolean; avoidState?: boolean }
  ) => void;
};

export const getPaginationProps = (
  items: unknown[],
  perPage: number = 20
): {
  pagination: boolean | Pagination;
  paginationProps: CSmartPaginationProps;
} => {
  if (items.length < perPage) {
    return {
      pagination: false,
      paginationProps: {
        pages: 0,
        dots: false,
        arrows: false,
        align: "center",
      },
    };
  }

  return {
    pagination: true,
    paginationProps: {
      pages: Math.ceil(items.length / perPage),
      dots: false,
      arrows: false,
      align: "center",
    },
  };
};

export const usePagination = (
  route: string,
  refetch?: ({ filters }: { filters: Generic }) => void,
  extraParams?: Generic
): PaginationProps => {
  const navigate = useNavigate();
  const { state, search: querySearch } = useLocation();
  const queryPage = RegExp(/page=(\d+)/).exec(querySearch);
  const currentPage = Number(queryPage?.[1] ?? 1);
  const [page, setPage] = useState(currentPage);
  const [params, setParams] = useState<Generic>();

  const pageChange = (newPage: number) => {
    const inputString = querySearch !== "" ? querySearch : `page=1`;

    const regexPattern = /\page=\d+(?=&|\b)/;
    const replacementString = `page=${newPage}`;
    const newQueryString = inputString.replace(regexPattern, replacementString);

    const searchParams = new URLSearchParams(newQueryString);
    const queryObject = Object.fromEntries(searchParams.entries());

    resetAndSearch(queryObject);
  };

  const resetAndSearch = (
    values: Generic,
    args?: { force?: boolean; avoidState?: boolean }
  ) => {
    const { force = false, avoidState = false } = args ?? {
      force: false,
      avoidState: false,
    };
    const newPage = values.page ? Number(values.page) : 1;

    const queryString = objectToQueryString({
      ...values,
      page: newPage.toString(),
    });

    setParams(values);

    let newState = {
      refetch: true,
    };

    if (!avoidState) {
      newState = {
        ...newState,
        ...state,
      };
    }

    navigate(`/${route}?${queryString}`, {
      state: newState,
    });

    if (values.trashed) {
      values.trashed = Number(values.trashed);
    }

    if (force) {
      refetch?.({
        filters: {
          ...extraParams,
          ...values,
          page: newPage,
        },
      });
    }
  };

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  return {
    currentPage,
    pageChange,
    page,
    setPage,
    params,
    resetAndSearch,
  };
};
