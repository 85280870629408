import { useMutation } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
  CFormSelect,
  CLoadingButton,
  CSmartTable,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import Api from "src/api";
import { ProductBarcode } from "src/api/products";
import { useProductError } from "src/hooks/useProductError";

const Barcodes = ({
  productId,
  items,
  refetch,
}: {
  productId: number;
  items: ProductBarcode[];
  refetch: () => void;
}) => {
  const { handleErrorMessage } = useProductError();
  const [createMutation, { loading: creating }] = useMutation(
    Api.Products.CREATE_BARCODE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
      onError: (error) => {
        handleErrorMessage(error);
      },
    }
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.Products.DELETE_BARCODE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      barcode: "",
      type: "EAN13",
    },
    onSubmit: () => {
      if (!creating && !deleting) {
        createMutation({
          variables: {
            productId,
            barcode: formik.values.barcode,
            type: formik.values.type,
          },
        });
      }
    },
  });

  return (
    <CCard className="mt-3">
      <CForm onSubmit={formik.handleSubmit}>
        <CCardHeader>Códigos de Barra</CCardHeader>
        <CCardBody>
          <CSmartTable
            items={[...items, {}]}
            itemsPerPage={items.length + 1}
            columns={[
              { key: "barcode", label: "Código" },
              { key: "type", label: "Tipo" },
              {
                key: "actions",
                label: "Acciones",
                _props: { className: "text-right" },
              },
            ]}
            scopedColumns={{
              barcode: (item: ProductBarcode) =>
                item.id ? (
                  <td>{item.barcode}</td>
                ) : (
                  <td>
                    <CFormInput
                      name="barcode"
                      onChange={formik.handleChange}
                      placeholder="XXX12323212"
                    />
                  </td>
                ),
              type: (item: ProductBarcode) =>
                item.id ? (
                  <td>{item.type}</td>
                ) : (
                  <td>
                    <CFormSelect
                      id="type"
                      name="type"
                      onChange={formik.handleChange}
                      value={formik.values.type}
                    >
                      <option key="0" value="0">
                        Seleccione Tipo
                      </option>
                      <option value="EAN13">EAN13</option>
                      <option value="DUN14">DUN14</option>
                    </CFormSelect>
                  </td>
                ),
              actions: (item: ProductBarcode) => (
                <td align="right">
                  {item.id ? (
                    <CLoadingButton
                      onClick={() =>
                        deleteMutation({
                          variables: {
                            id: item.id,
                          },
                        })
                      }
                      disabled={deleting || creating}
                      size="sm"
                      color="danger"
                    >
                      Eliminar Código
                    </CLoadingButton>
                  ) : (
                    <CLoadingButton
                      onClick={() => formik.handleSubmit()}
                      disabled={deleting || creating}
                      size="sm"
                      color="success"
                    >
                      Crear Código
                    </CLoadingButton>
                  )}
                </td>
              ),
            }}
            tableProps={{
              striped: true,
              hover: true,
            }}
          />
        </CCardBody>
      </CForm>
    </CCard>
  );
};

export default Barcodes;
