import { gql } from "@apollo/client";
import yup from "src/helpers/validation";
import { Product } from "./products";

export const AddShipmentItemSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  cost: yup.number().min(0.1).required(),
  quantity: yup.number().integer().min(0.1).required(),
});

export type ShipmentItem = {
  id: number;
  productId: number;

  orderedQuantity: number;
  orderedPrice: number;
  orderedTax: number;

  confirmedQuantity: number;
  confirmedPrice: number;
  confirmedTax: number;

  deliveredQuantity: number;
  deliveredPrice: number;
  deliveredTax: number;

  product: Product;
};

export enum ShipmentStatus {
  Open = "OPEN",
  Closed = "CLOSED",

  Sent = "SENT",
  Cancelled = "CANCELLED",
  Confirmed = "CONFIRMED",
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type Shipment = {
  id: number;
  companyId: number;
  storeId: number;
  supplierId: number;
  status: ShipmentStatus;

  orderedTotal: number;
  confirmedTotal: number;
  deliveredTotal: number;

  orderedAt: Date;
  confirmedAt: Date;
  deliveredAt: Date;

  createdAt: Date;

  items: ShipmentItem[];
};

export const LIST_SHIPMENTS = gql`
  query shipments($filters: ShipmentFilters) {
    data: shipments(filters: $filters) {
      data {
        id
        status

        orderedTotal
        confirmedTotal
        deliveredTotal

        orderedAt
        confirmedAt
        deliveredAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_SHIPMENT = gql`
  query shipment($id: Int!) {
    data: shipment(id: $id) {
      id
      status
      orderedAt
      deliveredAt
      createdAt

      items {
        id
        productId

        orderedQuantity
        orderedPrice
        orderedTax

        confirmedQuantity
        confirmedPrice
        confirmedTax

        deliveredQuantity
        deliveredPrice
        deliveredTax

        product {
          name
        }
      }
    }
  }
`;

export const CREATE_SHIPMENT = gql`
  mutation createShipment($input: CreateShipmentInput!) {
    data: createShipment(input: $input) {
      id
    }
  }
`;

export const UPDATE_SHIPMENT = gql`
  mutation updateShipment($id: Int!, $input: UpdateShipmentInput!) {
    data: updateShipment(id: $id, input: $input) {
      id
    }
  }
`;

export const ADD_ITEM_TO_SHIPMENT = gql`
  mutation addItemToShipment($input: AddItemToShipmentInput!) {
    data: addItemToShipment(input: $input) {
      id
    }
  }
`;

export const DELETE_ITEM_FROM_SHIPMENT = gql`
  mutation deleteItemFromShipment($id: Int!) {
    data: deleteItemFromShipment(id: $id) {
      id
    }
  }
`;
