import { useLazyQuery, useQuery } from "@apollo/client";
import { cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CLoadingButton,
  CRow,
  CSmartTable,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { add } from "date-fns/add";
import { isWithinInterval } from "date-fns/isWithinInterval";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Api from "src/api";
import { PaymentStatus } from "src/api/companies";
import { Stock, uploadBaseStock, WarehouseStock } from "src/api/stock";
import { Warehouse } from "src/api/warehouses";
import Icon from "src/components/Icon";
import { AppLoader, Loader } from "src/components/Loader/Loader";
import Pagination from "src/components/Pagination";
import SmartSelect from "src/components/SmartSelect";
import FeatureAlert from "src/containers/FeatureAlert";
import { logEvent } from "src/helpers/analytics";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { getCompanyPaymentStatus } from "src/helpers/payments";

import { queryStringToObject } from "src/helpers/strings";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";
import writeXlsxFile from "write-excel-file";

const StockScreen = () => {
  const { currentCompany } = useAdminStore();

  const { data: warehouses } = useQuery<GraphQLMeta<Warehouse>>(
    Api.Warehouses.LIST_WAREHOUSES,
    { fetchPolicy: "no-cache", variables: { filters: { limit: 0 } } }
  );

  if (!warehouses?.data?.data) {
    return <AppLoader />;
  }

  if (warehouses.data.data.length === 0) {
    return <p>No tienes depósitos creados y/o asignados</p>;
  }

  const isDebtor =
    getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
    PaymentStatus.DEBTOR;

  return (
    <>
      <FeatureAlert />

      {!isDebtor && <Form warehouses={warehouses.data.data ?? []} />}
    </>
  );
};

const Form = ({ warehouses }: { warehouses: Warehouse[] }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { hasPermission, user } = useAdminStore();
  const querySearch = queryStringToObject(useLocation().search);
  const [stockDetail, setStockDetail] = useState<number>();
  const [stockDetails, setStockDetails] = useState<
    Record<string, WarehouseStock[]>
  >({});
  const defaultWarehouseId = querySearch.warehouseId
    ? Number(querySearch.warehouseId)
    : warehouses.length === 1
    ? warehouses[0].id
    : 0;

  const {
    data: stock,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Stock>>(Api.Stock.LIST_STOCK, {
    fetchPolicy: "no-cache",
    skip: defaultWarehouseId === 0,
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        warehouseId: defaultWarehouseId,
        newWay: true,
      },
    },
  });

  const [fetchDetail, { loading: fetching }] = useLazyQuery(
    Api.Stock.FETCH_WAREHOUSE_STOCK,
    {
      onCompleted: ({
        data: res,
      }: {
        data: {
          productId: number;
          warehouseId: number;
          data: WarehouseStock[];
        };
      }) => {
        const productId = res.productId;

        setStockDetails((prev) => ({
          ...prev,
          [productId]: res.data,
        }));
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      warehouseId: defaultWarehouseId,
    },
    onSubmit: (data) => {
      resetAndSearch({
        warehouseId: Number(data.warehouseId),
        page: 1,
      });
    },
  });

  const { page, pageChange, resetAndSearch } = usePagination("stock", refetch);

  const warehouseStoresIds =
    warehouses
      .find((w) => w.id === Number(formik.values.warehouseId))
      ?.stores?.map((s) => s.id) ?? [];

  const warehouseCompanyId = warehouses.find(
    (w) => w.id === Number(formik.values.warehouseId)
  )?.company.id;

  let canSeeBatches = false;
  let canSeeRetires = false;
  let canSeeMoves = false;

  if (warehouseStoresIds.length > 0) {
    canSeeBatches = warehouseStoresIds.some((storeId) =>
      hasPermission("LIST_BATCHES", { type: "store", id: storeId })
    );
    canSeeRetires = warehouseStoresIds.some((storeId) =>
      hasPermission("LIST_RETIRES", { type: "store", id: storeId })
    );
    canSeeMoves = warehouseStoresIds.some(
      (storeId) =>
        hasPermission("LIST_BATCHES", { type: "store", id: storeId }) &&
        hasPermission("LIST_RETIRES", { type: "store", id: storeId })
    );
  } else {
    canSeeBatches = hasPermission("LIST_BATCHES", {
      type: "company",
      id: warehouseCompanyId,
    });
    canSeeRetires = hasPermission("LIST_RETIRES", {
      type: "company",
      id: warehouseCompanyId,
    });
    canSeeMoves =
      hasPermission("LIST_BATCHES", {
        type: "company",
        id: warehouseCompanyId,
      }) &&
      hasPermission("LIST_RETIRES", {
        type: "company",
        id: warehouseCompanyId,
      });
  }

  const downloadExcel = () => {
    if (!stock?.data) {
      return;
    }

    const excelData: any = [
      user?.isAdmin
        ? [
            { value: "ID" },
            { value: "Nombre" },
            { value: "Costo" },
            { value: "Stock" },
            { value: "Fecha de Vencimiento" },
          ]
        : [
            { value: "ID" },
            { value: "Nombre" },
            { value: "Proveedor" },
            { value: "Categoria" },
            { value: "Stock" },
            { value: "Costo" },
            { value: "Precio" },
            { value: "Costo Total" },
            { value: "Precio Total" },
          ],
    ];

    let itemTotals = 0;
    let costTotals = 0;
    let priceTotals = 0;

    stock.data.data.forEach((stockItem) => {
      itemTotals += stockItem.stock;
      costTotals += stockItem.cost * stockItem.stock;
      priceTotals += stockItem.product.price * stockItem.stock;

      excelData.push(
        user?.isAdmin
          ? [
              {
                value: stockItem.product.id,
              },
              {
                value: stockItem.product.name,
              },
              {
                value: stockItem.cost,
              },
              {
                value: stockItem.stock,
              },
              {
                value: "",
              },
            ]
          : [
              {
                value: stockItem.product.id,
              },
              {
                value: stockItem.product.name,
              },
              {
                value: stockItem.product.supplier?.name?.toUpperCase() ?? "",
              },
              {
                value: stockItem.product.category?.name?.toUpperCase() ?? "",
              },
              {
                value: stockItem.stock,
              },
              {
                value: formatCurrency(stockItem.cost),
              },
              {
                value: formatCurrency(stockItem.product.price),
              },
              {
                value: formatCurrency(stockItem.cost * stockItem.stock),
              },
              {
                value: formatCurrency(
                  stockItem.product.price * stockItem.stock
                ),
              },
            ]
      );
    });

    if (!user?.isAdmin) {
      excelData.push([
        { value: "TOTAL" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: itemTotals },
        { value: "" },
        { value: "" },
        { value: formatCurrency(costTotals) },
        { value: formatCurrency(priceTotals) },
      ]);
    }

    const currentWarehouse = warehouses.find(
      (w) => w.id === Number(formik.values.warehouseId)
    );

    writeXlsxFile(excelData, {
      fileName: `Stock Valorizado del ${currentWarehouse?.name}.xlsx`,
      sheet: "Stock Valorizado",
    });
  };

  const uploadStock = async (e) => {
    if (
      fileInputRef.current &&
      e.target.value !== "" &&
      fileInputRef.current.files?.[0]
    ) {
      const formData = new FormData();

      formData.append("file", fileInputRef.current.files[0]);
      formData.append("warehouseId", formik.values.warehouseId?.toString());

      try {
        await uploadBaseStock(formData);

        fileInputRef.current.value = "";
      } catch (e) {
        fileInputRef.current.value = "";
      }
    }
  };

  const warehouseName =
    warehouses.find((w) => w.id === Number(formik.values.warehouseId))?.name ??
    "";

  const items = useMemo(() => {
    if (formik.values.warehouseId === 0) {
      return [];
    }

    return stock?.data?.data ?? [];
  }, [formik.values.warehouseId, stock?.data?.data]);

  const today = new Date();
  const limitDate = add(today, { days: 10 });

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={9} className="px-0">
                Stock
              </CCol>
              <CCol sm={3} className="row justify-content-end"></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm={5}>
                <SmartSelect
                  search
                  defaultValue={formik.values.warehouseId?.toString()}
                  onChange={(e) => {
                    logEvent("stock.warehouses.set-warehouse", {
                      warehouseId: e,
                    });

                    formik.setFieldValue("warehouseId", e ?? 0);
                    formik.handleSubmit();
                  }}
                  name="warehouseId"
                  options={[
                    { value: 0, name: "Selecciona un depósito" },
                    ...warehouses.map((warehouse) => ({
                      value: warehouse.id,
                      name: warehouse.name,
                    })),
                  ]}
                />
              </CCol>
              <CCol sm={7}>
                <CRow className="d-flex justify-content-end">
                  {user?.isAdmin && (
                    <>
                      <CLoadingButton
                        size="sm"
                        color="danger"
                        className="w-auto mr-2"
                        onClick={() => {
                          fileInputRef.current?.click();
                        }}
                      >
                        Resetear Base
                      </CLoadingButton>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={uploadStock}
                        style={{ display: "none" }}
                        accept=".xls, .xlsx"
                      />
                    </>
                  )}

                  {formik.values.warehouseId > 0 && (
                    <CLoadingButton
                      size="sm"
                      disabled={loading}
                      color="warning"
                      className="w-auto mr-2"
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                      Stock en Excel
                    </CLoadingButton>
                  )}

                  {canSeeRetires && (
                    <Link
                      to="/stock/remove"
                      state={{
                        warehouseId: formik.values.warehouseId,
                        warehouseName,
                        companyId: warehouseCompanyId,
                        storeIds:
                          warehouses
                            .find(
                              (w) => w.id === Number(formik.values.warehouseId)
                            )
                            ?.stores?.map((s) => s.id) ?? [],
                      }}
                      className="w-auto p-0 mr-2"
                    >
                      <CButton
                        size="sm"
                        color="info"
                        onClick={() => {
                          logEvent("stock.remove");
                        }}
                      >
                        Egresos
                      </CButton>
                    </Link>
                  )}
                  {canSeeMoves && (
                    <Link
                      to="/stock/move"
                      state={{
                        warehouseId: formik.values.warehouseId,
                        warehouseName,
                        companyId: warehouseCompanyId,
                        storeIds:
                          warehouses
                            .find(
                              (w) => w.id === Number(formik.values.warehouseId)
                            )
                            ?.stores?.map((s) => s.id) ?? [],
                      }}
                      className="w-auto p-0 mr-2"
                    >
                      <CButton
                        color="success"
                        size="sm"
                        onClick={() => {
                          logEvent("stock.move", {
                            from: formik.values.warehouseId,
                          });
                        }}
                      >
                        Mover Stock
                      </CButton>
                    </Link>
                  )}
                  {canSeeBatches && (
                    <Link
                      to="/stock/add"
                      state={{
                        warehouseId: formik.values.warehouseId,
                        warehouseName,
                        companyId: warehouseCompanyId,
                        storeIds:
                          warehouses
                            .find(
                              (w) => w.id === Number(formik.values.warehouseId)
                            )
                            ?.stores?.map((s) => s.id) ?? [],
                      }}
                      className="w-auto p-0"
                    >
                      <CButton
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={() => {
                          logEvent("stock.add");
                        }}
                      >
                        Ingresos
                      </CButton>
                    </Link>
                  )}
                </CRow>
              </CCol>
            </CRow>

            {formik.values.warehouseId > 0 && (
              <CRow className="mt-3">
                <CSmartTable
                  itemsPerPage={20}
                  loading={
                    !stock?.data?.data && formik.values.warehouseId !== 0
                  }
                  items={
                    items.map((item) => ({
                      ...item,
                      _props: {
                        color: item.isLowStock ? "warning" : "default",
                      },
                    })) ?? []
                  }
                  tableProps={{
                    striped: true,
                  }}
                  columnFilter
                  columns={[
                    {
                      key: "productName",
                      label: "Producto",
                      _props: { className: "font-weight-bold" },
                    },
                    {
                      key: "barcode",
                      label: "Código de Barra",
                      _props: { className: "font-weight-bold" },
                      filter: false,
                    },
                    {
                      key: "stock",
                      label: "Stock actual",
                      _props: { className: "font-weight-bold text-center" },
                      filter: false,
                    },
                    {
                      key: "cost",
                      label: "Costo actual",
                      _props: { className: "font-weight-bold text-right" },
                      filter: false,
                    },
                    {
                      key: "lastUpdate",
                      label: "Última actualización",
                      _props: { className: "font-weight-bold text-right" },
                      filter: false,
                    },
                    {
                      key: "actions",
                      label: "",
                      _props: { className: "font-weight-bold text-right" },
                      filter: false,
                    },
                  ]}
                  scopedColumns={{
                    productName: (item: Stock) => {
                      const nearToExpire =
                        item.nextExpiresAt &&
                        isWithinInterval(item.nextExpiresAt, {
                          start: today,
                          end: limitDate,
                        });

                      return (
                        <td>
                          <span className="d-flex align-items-center">
                            {item.nextExpiresAt && nearToExpire && (
                              <CTooltip
                                content={
                                  <span>
                                    Stock cargado con fecha de vencimiento
                                    proxima.{" "}
                                    {dateFormat(
                                      item.nextExpiresAt,
                                      "dd/MM/yyyy"
                                    )}
                                  </span>
                                }
                              >
                                <CIcon
                                  size="lg"
                                  color="danger"
                                  style={{
                                    color: "red",
                                    marginRight: 8,
                                  }}
                                  icon={cilWarning}
                                />
                              </CTooltip>
                            )}

                            {item.productName.replace(
                              `(${item.product.barcodes
                                ?.map(({ barcode }) => barcode)
                                .join(", ")})`,
                              ""
                            )}
                          </span>
                        </td>
                      );
                    },
                    barcode: (item: Stock) => (
                      <td>
                        {item.product.barcodes
                          ?.map(({ barcode }) => barcode)
                          .join(", ")}
                      </td>
                    ),
                    stock: (item: Stock) => (
                      <td className="text-center">{item.stock}</td>
                    ),
                    cost: (item: Stock) => (
                      <td className="text-right">
                        {formatCurrency(item.cost)}
                      </td>
                    ),
                    lastUpdate: (item: Stock) => (
                      <td className="text-right">
                        {dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm")}
                      </td>
                    ),
                    actions: (item: Stock) => (
                      <td className="text-right">
                        <CButton
                          size="sm"
                          variant="ghost"
                          color="secondary"
                          onClick={() => {
                            if (stockDetail === item.product.id) {
                              setStockDetail(undefined);
                              return;
                            }

                            setStockDetail(item.product.id);

                            fetchDetail({
                              variables: {
                                warehouseId: formik.values.warehouseId,
                                productId: item.product.id,
                              },
                            });
                          }}
                        >
                          <Icon
                            name={
                              stockDetail === item.product.id
                                ? "chevron-up"
                                : "chevron-down"
                            }
                            color="black"
                          />
                        </CButton>
                      </td>
                    ),
                    details: (item: Item) => {
                      const itemsLength = stockDetail
                        ? stockDetails[stockDetail]?.length ?? 0
                        : 0;

                      return (
                        <CCollapse visible={stockDetail === item.product.id}>
                          <CTable striped>
                            <CTableHead>
                              <CTableRow>
                                <CTableHeaderCell scope="col">
                                  Usuario
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                  Notas
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                  Monto Modificado
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="text-right"
                                >
                                  Costo
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="text-right"
                                >
                                  Fecha de Cambio
                                </CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody>
                              {stockDetail && (
                                <>
                                  {fetching ? (
                                    <CTableRow>
                                      <CTableDataCell colSpan={5}>
                                        <Loader />
                                      </CTableDataCell>
                                    </CTableRow>
                                  ) : (
                                    <>
                                      {itemsLength === 0 && (
                                        <CTableRow>
                                          <CTableDataCell
                                            colSpan={5}
                                            className="text-center"
                                          >
                                            No hay movimientos para este
                                            producto
                                          </CTableDataCell>
                                        </CTableRow>
                                      )}

                                      {stockDetails[stockDetail]?.map(
                                        (stockItem: WarehouseStock) => (
                                          <CTableRow key={stockItem.id}>
                                            <CTableDataCell>
                                              Modificado por{" "}
                                              {stockItem.batch
                                                ? stockItem.batch.user.name
                                                : stockItem.retire.user.name}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                              {stockItem.notes}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                              {stockItem.retireId === 0 && "-"}
                                              {stockItem.quantity}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-right">
                                              {stockItem.batch
                                                ? formatCurrency(stockItem.cost)
                                                : "-"}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-right">
                                              {stockItem.batch
                                                ? dateFormat(
                                                    stockItem.batch.updatedAt,
                                                    "dd/MM/yyyy HH:mm"
                                                  )
                                                : dateFormat(
                                                    stockItem.retire.updatedAt,
                                                    "dd/MM/yyyy HH:mm"
                                                  )}
                                            </CTableDataCell>
                                          </CTableRow>
                                        )
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </CTableBody>
                          </CTable>
                        </CCollapse>
                      );
                    },
                  }}
                />

                <Pagination meta={stock} page={page} pageChange={pageChange} />
              </CRow>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default StockScreen;
