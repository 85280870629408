import { useLazyQuery, useQuery } from "@apollo/client";
import { cilCloudDownload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCol,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import Api from "src/api";
import { Warehouse } from "src/api/warehouses";
import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { getPaginationProps } from "src/hooks/pagination";
import { GraphQLFind, GraphQLMeta } from "src/types";
import writeXlsxFile from "write-excel-file";

type StockValue = {
  productId: number;
  productName: string;
  quantity: number;
  cost: number;
  totalCost: number;
  price: number;
  totalPrice: number;
  updatedAt: Date;
};

const WarehouseStockValue = () => {
  const [warehouseId, setWarehouseId] = useState<number>(0);
  const [items, setItems] = useState<StockValue[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);

  const { data: warehouses } = useQuery<GraphQLMeta<Warehouse>>(
    Api.Warehouses.LIST_WAREHOUSES,
    { fetchPolicy: "no-cache", variables: { filters: { limit: 0 } } }
  );

  const [fetchData, { loading }] = useLazyQuery<GraphQLFind<StockValue[]>>(
    Api.Warehouses.GET_STOCK_VALUE,
    {
      fetchPolicy: "no-cache",
      variables: {
        warehouseId,
      },
      onCompleted: ({ data }) => {
        setItems(data);
      },
    }
  );

  useEffect(() => {
    if (warehouseId === 0) {
      setItems([]);
    } else {
      fetchData({
        variables: {
          warehouseId,
        },
      });
    }
  }, [fetchData, warehouseId]);

  if (!warehouses?.data?.data) {
    return <AppLoader />;
  }

  if (warehouses.data.data.length === 0) {
    return <p>No tienes depósitos creados y/o asignados</p>;
  }

  const downloadFile = () => {
    if (!items || items.length === 0) {
      return;
    }

    if (!downloading) {
      try {
        setDownloading(true);

        const excelData: any = [
          [
            { value: "ID", align: "center" },
            { value: "Nombre" },
            { value: "Stock" },
            { value: "Costo", align: "right" },
            { value: "Costo Total", align: "right" },
            { value: "Precio", align: "right" },
            { value: "Precio Total", align: "right" },
            { value: "Ultimo cambio de stock", align: "right" },
          ],
        ];

        items.forEach((item) => {
          excelData.push([
            {
              value: item.productId,
              align: "center",
            },
            {
              value: item.productName,
            },
            {
              value: item.quantity,
              align: "center",
            },
            {
              value: item.cost,
              align: "right",
            },
            {
              value: item.totalCost,
              align: "right",
            },
            {
              value: item.price,
              align: "right",
            },
            {
              value: item.totalPrice,
              align: "right",
            },
            {
              value: dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm 'hs'"),
              align: "right",
            },
          ]);
        });

        excelData.push([
          {
            value: "Totales",
            align: "left",
          },
          "",
          "",
          "",
          {
            value: formatCurrency(
              items.reduce((acc, item) => {
                return acc + item.totalCost;
              }, 0)
            ),
            align: "right",
          },
          "",
          {
            value: formatCurrency(
              items.reduce((acc, item) => {
                return acc + item.totalPrice;
              }, 0)
            ),
            align: "right",
          },
        ]);

        const currentWarehouse = warehouses.data.data.find(
          (w) => w.id === warehouseId
        );

        writeXlsxFile(excelData, {
          fileName: `Stock valorizado de ${currentWarehouse?.name}.xlsx`,
          sheet: "Productos en Stock",
        });
      } catch (e) {
        logEvent("download_stock_value.error");
      } finally {
        setDownloading(false);
      }
    }
  };

  const generateFooter = () => {
    const footerStack = [
      {
        label: "Totales",
      },
      "",
      {
        label: formatCurrency(
          items.reduce((acc, item) => {
            return acc + item.totalCost;
          }, 0)
        ),
        _props: { className: "text-right" },
      },
      {
        label: formatCurrency(
          items.reduce((acc, item) => {
            return acc + item.totalPrice;
          }, 0)
        ),
        _props: { className: "text-right" },
      },
      "",
    ];

    return footerStack;
  };

  return (
    <>
      <CRow>
        <CCol className="product-search" sm={6}>
          <CFormLabel className="pl-2">
            <strong>Depósito</strong>
          </CFormLabel>
          <SmartSelect
            search
            defaultValue={warehouseId?.toString()}
            onChange={(e) => {
              setWarehouseId(Number(e));
            }}
            name="warehouseId"
            options={[
              { value: 0, name: "Selecciona un depósito" },
              ...warehouses.data.data.map((warehouse) => ({
                value: warehouse.id,
                name: warehouse.name,
              })),
            ]}
          />
        </CCol>

        <CCol sm={6} className="text-right">
          {warehouseId > 0 && (
            <CLoadingButton
              loading={downloading}
              disabled={downloading}
              onClick={downloadFile}
              type="button"
              color="primary"
              size="sm"
            >
              <CIcon icon={cilCloudDownload} className="mr-2" />
              Descargar Reporte
            </CLoadingButton>
          )}
        </CCol>
      </CRow>

      {warehouseId > 0 && (
        <CRow className="mt-3">
          <CSmartTable
            loading={loading}
            columnSorter
            columnFilter
            footer={generateFooter()}
            itemsPerPage={20}
            items={items ?? []}
            {...getPaginationProps(items)}
            columns={[
              {
                key: "productName",
                label: "Nombre",
                _props: { className: "font-weight-bold" },
              },
              {
                key: "quantity",
                label: "Stock",
                _props: { className: "text-center" },
                filter: false,
              },
              {
                key: "cost",
                label: "Costo",
                _props: { className: "text-right" },
                filter: false,
              },
              {
                key: "price",
                label: "Precio",
                _props: { className: "text-right" },
                filter: false,
              },
              {
                key: "updatedAt",
                label: "Ultimo cambio de stock",
                _props: { className: "text-right" },
                filter: false,
              },
            ]}
            scopedColumns={{
              productName: (item: StockValue) => (
                <td>
                  {item.productId} - {item.productName}
                </td>
              ),
              quantity: (item: StockValue) => (
                <td className="text-center">{item.quantity}</td>
              ),
              cost: (item: StockValue) => (
                <td className="text-right">
                  {formatCurrency(item.totalCost)} ({formatCurrency(item.cost)})
                </td>
              ),
              price: (item: StockValue) => (
                <td className="text-right">
                  {formatCurrency(item.totalPrice)} (
                  {formatCurrency(item.price)})
                </td>
              ),
              updatedAt: (item: StockValue) => (
                <td className="text-right">
                  {dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm 'hs'")}
                </td>
              ),
            }}
            tableProps={{
              striped: true,
            }}
          />
        </CRow>
      )}
    </>
  );
};

export default WarehouseStockValue;
