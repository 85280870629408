import {
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react-pro";
import { useState } from "react";
import ProductSales from "./components/ProductSales";
import PlanAlert from "src/containers/PlanAlert";
import { useAdminStore } from "src/store";
import { AppLoader } from "src/components/Loader/Loader";
import WarehouseStockValue from "./components/WarehouseStockValue";

const Reports = () => {
  const { currentCompany } = useAdminStore();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const onTabChange = (newIndex: number) => {
    setCurrentTab(newIndex);
  };

  if (!currentCompany) {
    return <AppLoader />;
  }

  return (
    <>
      <PlanAlert />
      <CCard className="p-2">
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink onClick={() => onTabChange(0)} active={currentTab === 0}>
              Ventas por Producto
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(1)} active={currentTab === 1}>
              Stock Valorizado
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent className="px-1">
          <CTabPane visible={currentTab === 0}>
            <ProductSales companyId={currentCompany.id} />
          </CTabPane>
          <CTabPane visible={currentTab === 1}>
            <WarehouseStockValue />
          </CTabPane>
        </CTabContent>
      </CCard>
    </>
  );
};

export default Reports;
