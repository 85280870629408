import { useEffect, useRef, useState } from "react";
import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CModal,
  CModalHeader,
  CModalFooter,
  CFormSelect,
  CCardHeader,
  CLoadingButton,
  CFormSwitch,
  CMultiSelect,
} from "@coreui/react-pro";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UpdateProduct, taxes, Product } from "src/api/products";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";

import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { Supplier } from "src/api/suppliers";
import { Category } from "src/api/categories";
import { useAdminStore } from "src/store";
import { AppLoader } from "src/components/Loader/Loader";
import { calculatePrice, calculateProfit } from "src/helpers/numbers";
import SuccessModal from "./components/SuccessModal";
import { logEvent } from "src/helpers/analytics";
import { Company } from "src/api/companies";
import { StockType } from "src/api/stock";
import AuditCard from "./components/AuditCard";
import Barcodes from "./components/Barcodes";
import ProductConnections from "./components/ProductConnections";
import { useProductError } from "src/hooks/useProductError";
import ProductImages from "./components/ProductImages";

export const getInitialType = (product: Product): "profit" | "price" => {
  const calculatedPrice = calculatePrice(
    product.cost,
    product.profit,
    product.tax
  );

  if (product.price !== calculatedPrice) {
    return "profit";
  }

  return "price";
};

const EditProduct = () => {
  const { user } = useAdminStore();
  const params = useParams();
  const navigate = useNavigate();
  const productId = Number(params.id);
  const [company, setCompany] = useState<Company>();

  if (!productId) {
    navigate("/products");
  }

  const [fetchCompany] = useLazyQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      fetchPolicy: "no-cache",
      onError: () => {
        navigate(-1);
      },
      onCompleted: ({ data }) => {
        setCompany(data);
      },
    }
  );

  const { data: product, refetch } = useQuery<GraphQLFind<Product>>(
    Api.Products.GET_PRODUCT,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: productId,
        trashed: true,
      },
      onError: () => {
        window.location.href = "/#/products";
      },
    }
  );

  const { data: suppliers } = useQuery<GraphQLMeta<Supplier>>(
    Api.Suppliers.LIST_SUPPLIERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const { data: categories } = useQuery<GraphQLMeta<Category>>(
    Api.Categories.LIST_CATEGORIES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  useEffect(() => {
    if (product?.data && !company) {
      fetchCompany({
        variables: {
          id: product.data.companyId,
        },
      });
    }
  }, [user, product?.data, company, fetchCompany]);

  if (
    !product?.data ||
    !suppliers?.data.data ||
    !categories?.data.data ||
    !company
  ) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form
        refetch={refetch}
        company={company}
        product={product.data}
        categories={categories.data.data}
        suppliers={suppliers.data.data}
      />
    </CCol>
  );
};

const Form = ({
  categories,
  company,
  product,
  suppliers,
  refetch,
}: {
  company: Company;
  categories: Category[];
  product: Product;
  suppliers: Supplier[];
  refetch: () => void;
}) => {
  const { handleErrorMessage } = useProductError();
  const [showAudit, setShowAudit] = useState<boolean>(false);
  const successModalRef = useRef<any>(null);
  const navigate = useNavigate();
  const { hasPermission } = useAdminStore();
  const canEditProduct =
    hasPermission("UPDATE_PRODUCT") && product.deletedAt === null;
  const canDeleteProduct =
    hasPermission("DELETE_PRODUCT") && product.deletedAt === null;
  const canRecoverProduct =
    hasPermission("UPDATE_PRODUCT") && product.deletedAt !== null;

  const [showModal, setShowModal] = useState<boolean>(false);

  const [updateMutation, { error: updError, loading: updLoading }] =
    useMutation(Api.Products.UPDATE_PRODUCT, {
      onCompleted: () => {
        if (product.deletedAt === null) {
          successModalRef.current?.open(product.id);
        } else {
          window.location.reload();
        }
      },
      onError: (error) => {
        handleErrorMessage(error);
      },
    });

  const [deleteMutation, { error: delError, loading: delLoading }] =
    useMutation(Api.Products.DELETE_PRODUCT, {
      onCompleted: () => {
        navigate(-1);
      },
    });

  const onDelete = () => {
    if (!delLoading) {
      deleteMutation({
        variables: {
          id: product.id,
        },
      });
    }
  };

  const formik = useFormik<UpdateProduct>({
    initialValues: {
      ...product,
      barcode: product.barcodes?.[0]?.barcode ?? "",
      barcodeType: product.barcodes?.[0]?.type ?? "EAN13",
      profit:
        product.profit === 0 && product.price !== product.cost
          ? calculateProfit(product.cost, product.price, product.tax)
          : product.profit,
      categoryId: product.category?.id ?? 0,
      supplierIds: product.suppliers.map((s) => s.supplierId) ?? [],
    },
    onSubmit: (data) => {
      if (!updLoading) {
        const categoryId = data.categoryId ? Number(data.categoryId) : null;

        const input = {
          name: data.name?.trim(),
          sku: data.sku?.trim(),
          brands: data.brands?.trim(),
          barcode: data.barcode?.trim(),
          barcodeType: data.barcodeType,
          categoryId: categoryId && categoryId > 0 ? categoryId : null,
          productExpires: data.productExpires,
          multipleBarcodes: data.multipleBarcodes,
          stockeable:
            company.config.stock === StockType.Mixed
              ? data.stockeable
              : company.config.stock === StockType.Limited,
          cost: Number(data.cost),
          profit: Number(data.profit),
          tax: Number(data.tax),
          price: Number(data.price),
          supplierIds: data.supplierIds,
        } as UpdateProduct;

        if (data.stockeable === true) {
          input.stockAlert = Number(data.stockAlert);
        }

        logEvent("product.update.submit", {
          product,
          input,
        });

        updateMutation({
          variables: {
            id: product.id,
            input,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.profit < 0) {
      formik.setFieldValue("profit", Math.abs(formik.values.profit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.profit]);

  const options = suppliers.map((supplier) => ({
    value: supplier.id,
    text: supplier.name,
    label: supplier.name,
    selected: formik.values.supplierIds.includes(supplier.id),
  }));

  return (
    <>
      <CCard>
        <CForm onSubmit={formik.handleSubmit}>
          <CCardHeader>Producto: {product.name}</CCardHeader>
          <CCardBody>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  readOnly={!canEditProduct}
                  placeholder="Nombre"
                  defaultValue={formik.values.name}
                  name="name"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            {!formik.values.multipleBarcodes && (
              <CRow className="form-row">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="barcode">Código de Barra</CFormLabel>
                </CCol>
                <CCol xs="6" md="2">
                  <CFormSelect
                    id="barcodeType"
                    name="barcodeType"
                    onChange={formik.handleChange}
                    value={formik.values.barcodeType}
                  >
                    <option key="0" value="0">
                      Seleccione Tipo
                    </option>
                    <option value="EAN13">EAN13</option>
                    <option value="DUN14">DUN14</option>
                  </CFormSelect>
                </CCol>
                <CCol xs="6" md="7">
                  <CFormInput
                    id="barcode"
                    readOnly={!canEditProduct}
                    placeholder="Código de Barra"
                    defaultValue={formik.values.barcode}
                    onKeyDownCapture={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    name="barcode"
                    onChange={formik.handleChange}
                    {...getValidity(
                      formik.values.barcode,
                      formik.errors.barcode
                    )}
                  />
                </CCol>
              </CRow>
            )}
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="sku">SKU</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="sku"
                  readOnly={!canEditProduct}
                  placeholder="SKU"
                  defaultValue={formik.values.sku}
                  name="sku"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.sku, formik.errors.sku)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="supplierId">Proveedor(es)</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CMultiSelect
                  id="supplierIds"
                  options={options}
                  placeholder="Seleccione Proveedor"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "supplierIds",
                      e.map(({ value }) => value)
                    );
                  }}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="brands">Marca</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="brands"
                  placeholder="Marca"
                  defaultValue={formik.values.brands ?? ""}
                  name="brands"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.brands, formik.errors.brands)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="categoryId">Categoría</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="categoryId"
                  name="categoryId"
                  disabled={!canEditProduct}
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.categoryId,
                    formik.errors.categoryId
                  )}
                  defaultValue={formik.values.categoryId ?? 0}
                >
                  <option key="0" value="0">
                    Seleccione Categoría
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="cost">Costo</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="cost"
                  placeholder="Costo"
                  defaultValue={formik.values.cost}
                  name="cost"
                  readOnly={!canEditProduct}
                  type="number"
                  step="0.01"
                  onChange={(e) => {
                    formik.handleChange(e);

                    const price = calculatePrice(
                      Number(e.currentTarget.value),
                      Number(formik.values.profit),
                      Number(formik.values.tax),
                      company.config
                    );

                    formik.setFieldValue("price", price);
                  }}
                  {...getValidity(formik.values.cost, formik.errors.cost)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="profit">Ganancia (%)</CFormLabel>
              </CCol>
              <CCol md="9">
                <CFormInput
                  id="profit"
                  step="0.01"
                  placeholder="Ganancia (%)"
                  name="profit"
                  type="number"
                  disabled={
                    !canEditProduct || company.config.calculate === "PROFIT"
                  }
                  onChange={(e) => {
                    formik.handleChange(e);

                    const price = calculatePrice(
                      Number(formik.values.cost),
                      Number(e.currentTarget.value),
                      Number(formik.values.tax),
                      company.config
                    );

                    formik.setFieldValue("price", price);
                  }}
                  defaultValue={formik.values.profit}
                  value={formik.values.profit}
                  {...getValidity(formik.values.profit, formik.errors.profit)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="tax">IVA</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="tax"
                  name="tax"
                  disabled={!canEditProduct}
                  defaultValue={formik.values.tax}
                  onChange={(e) => {
                    formik.handleChange(e);

                    const price = calculatePrice(
                      Number(formik.values.cost),
                      Number(formik.values.profit),
                      Number(e.currentTarget.value),
                      company.config
                    );

                    formik.setFieldValue("price", price);
                  }}
                  {...getValidity(formik.values.tax, formik.errors.tax)}
                >
                  <option key="-1" value="-1">
                    Seleccione IVA
                  </option>
                  {taxes.map((tax) => (
                    <option key={tax.id} value={tax.value}>
                      {tax.value} %
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="price">Precio</CFormLabel>
              </CCol>
              <CCol md="9">
                <CFormInput
                  id="price"
                  placeholder="Precio"
                  name="price"
                  step="0.01"
                  disabled={!canEditProduct}
                  value={formik.values.price}
                  defaultValue={formik.values.price}
                  onChange={(e) => {
                    formik.handleChange(e);

                    const profit = calculateProfit(
                      Number(formik.values.cost),
                      Number(e.currentTarget.value),
                      Number(formik.values.tax)
                    );

                    formik.setFieldValue("profit", profit);
                  }}
                  type="number"
                />
              </CCol>
            </CRow>

            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="multipleBarcodes">
                  Multiple Códigos de Barra
                </CFormLabel>
              </CCol>
              <CCol md="9">
                <CFormSwitch
                  id="multipleBarcodes"
                  name="multipleBarcodes"
                  disabled={!canEditProduct}
                  defaultChecked={formik.values.multipleBarcodes}
                  onChange={formik.handleChange}
                />
              </CCol>
            </CRow>

            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="productExpires">
                  Producto con Vencimiento?
                </CFormLabel>
              </CCol>
              <CCol md="9">
                <CFormSwitch
                  id="productExpires"
                  name="productExpires"
                  disabled={!canEditProduct}
                  defaultChecked={formik.values.productExpires}
                  onChange={formik.handleChange}
                />
              </CCol>
            </CRow>

            {company.config.stock === StockType.Mixed && (
              <CRow className="form-row">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="stockeable">¿Maneja Stock?</CFormLabel>
                </CCol>
                <CCol md="9">
                  <CFormSwitch
                    id="stockeable"
                    name="stockeable"
                    disabled={!canEditProduct}
                    defaultChecked={formik.values.stockeable}
                    onChange={formik.handleChange}
                  />
                </CCol>
              </CRow>
            )}

            {(company.config.stock === StockType.Limited ||
              (company.config.stock === StockType.Mixed &&
                formik.values.stockeable)) && (
              <CRow className="form-row">
                <CCol md="3" className="table-center">
                  <CFormLabel htmlFor="stockAlert">Alerta de Stock</CFormLabel>
                </CCol>
                <CCol md="2">
                  <CFormInput
                    id="stockAlert"
                    name="stockAlert"
                    type="number"
                    step="1"
                    min={0}
                    disabled={!canEditProduct}
                    defaultValue={formik.values.stockAlert}
                    onChange={formik.handleChange}
                  />
                </CCol>
              </CRow>
            )}
          </CCardBody>
          {(canDeleteProduct || canEditProduct || canRecoverProduct) && (
            <CCardFooter className="px-3">
              <CRow className="px-0 align-items-center">
                <CCol md="6">
                  {(updError || delError) && <ErrorMessage />}{" "}
                  <CButton
                    type="button"
                    size="sm"
                    color="info"
                    onClick={() => setShowAudit((prev) => !prev)}
                  >
                    {!showAudit
                      ? "Ver Historial de Cambios"
                      : "Ocultar Historial de Cambios"}
                  </CButton>
                </CCol>
                <CCol md="6" className="flex justify-content-end">
                  {canDeleteProduct && (
                    <>
                      <CButton
                        type="button"
                        size="sm"
                        color="danger"
                        className="mr-2"
                        onClick={() => setShowModal(true)}
                      >
                        Eliminar
                      </CButton>

                      <CModal
                        size="sm"
                        alignment="center"
                        visible={showModal}
                        onClose={() => setShowModal(false)}
                      >
                        <CModalHeader closeButton>
                          ¿Estás seguro de eliminar este producto?
                        </CModalHeader>
                        <CModalFooter>
                          <CButton
                            size="sm"
                            color="secondary"
                            onClick={() => {
                              setShowModal(false);
                            }}
                          >
                            Cancelar
                          </CButton>
                          <CButton size="sm" color="danger" onClick={onDelete}>
                            Si, quiero eliminarlo
                          </CButton>
                        </CModalFooter>
                      </CModal>
                    </>
                  )}

                  {canEditProduct && (
                    <CLoadingButton
                      size="sm"
                      color="primary"
                      loading={updLoading}
                      disabled={updLoading || !formik.isValid}
                      type="submit"
                    >
                      Guardar
                    </CLoadingButton>
                  )}

                  {canRecoverProduct && (
                    <CLoadingButton
                      size="sm"
                      color="primary"
                      loading={updLoading}
                      disabled={updLoading || !formik.isValid}
                      type="submit"
                    >
                      Reactivar Producto
                    </CLoadingButton>
                  )}
                </CCol>
              </CRow>
            </CCardFooter>
          )}
        </CForm>
      </CCard>

      {showAudit && (
        <AuditCard
          categories={categories}
          suppliers={suppliers}
          productId={product.id}
        />
      )}

      {product.multipleBarcodes && (
        <Barcodes
          productId={product.id}
          items={product.barcodes ?? []}
          refetch={refetch}
        />
      )}

      <ProductConnections connections={product.connections} />

      <ProductImages product={product} refetch={refetch} />

      <SuccessModal ref={successModalRef} edit />
    </>
  );
};

export default EditProduct;
