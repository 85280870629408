import { useQuery } from "@apollo/client";
import { CChartLine } from "@coreui/react-chartjs";
import { CCol, CRow, CSmartTable, CWidgetStatsA } from "@coreui/react-pro";
import { subMonths } from "date-fns/subMonths";
import Api from "src/api";
import { CheckingAccount, CheckingAccountMove } from "src/api/checkingAccounts";
import { Customer } from "src/api/customers";
import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { findPaymentMethod } from "src/helpers/payments";
import { getPaginationProps } from "src/hooks/pagination";
import { GraphQLFind } from "src/types";

const CustomerChecking = ({ customer }: { customer: Customer }) => {
  const checkingAccountId = customer.accounts[0]?.id ?? 0;

  const { data: checking, loading } = useQuery<GraphQLFind<CheckingAccount>>(
    Api.CheckingAccounts.GET_ACCOUNT,
    {
      variables: {
        id: checkingAccountId,
      },
      skip: checkingAccountId === 0,
    }
  );

  if (checkingAccountId === 0 || !checking?.data) {
    return <AppLoader />;
  }

  const currentValue = checking.data.moves.reduce(
    (prev, move) => prev + move.amount,
    0
  );

  const lastSixMonths = Array.from(
    { length: 6 },
    (_, i) => dateFormat(subMonths(new Date(), i), "yyyy-MM") // Resta "i" meses al mes actual
  ).reverse();

  const groupedByMonth = checking.data.moves.reduce((acc, move) => {
    const monthKey = dateFormat(move.createdAt, "yyyy-MM");
    acc[monthKey] = (acc[monthKey] || 0) + move.amount;
    return acc;
  }, {});

  let runningTotal = 0;

  const result = lastSixMonths.map((date) => {
    const monthKey = dateFormat(date, "yyyy-MM");
    const monthName = dateFormat(date, "MMMM yyyy");
    const monthlyTotal = groupedByMonth[monthKey] || 0;
    runningTotal += monthlyTotal;

    return {
      month: monthName.charAt(0).toUpperCase() + monthName.slice(1),
      totalAmount: runningTotal,
    };
  });

  return (
    <>
      <CRow>
        <CCol sm={4}>
          <CWidgetStatsA
            className="mb-2"
            color="info"
            value={formatCurrency(currentValue)}
            title="Historico de Cuenta"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels: result.map((r) => r.month),
                  datasets: [
                    {
                      label: "Valor en Cuenta",
                      backgroundColor: "rgba(255,255,255,.2)",
                      borderColor: "rgba(255,255,255,.55)",
                      data: result.map((r) => r.totalAmount),
                      fill: true,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
      </CRow>
      <CRow>
        <CSmartTable
          itemsPerPage={20}
          items={checking.data.moves ?? []}
          {...getPaginationProps(checking.data.moves)}
          columns={[
            { key: "payments", label: "Método de Pago" },
            {
              key: "notes",
              label: "Notas",
            },
            {
              key: "amount",
              label: "Monto",
              _props: { className: "text-right" },
            },
            {
              key: "date",
              label: "Fecha",
              _props: { className: "font-weight-bold text-right" },
            },
          ]}
          loading={loading}
          scopedColumns={{
            payments: (move: CheckingAccountMove) => (
              <td>{findPaymentMethod(move.paymentMethod)?.name}</td>
            ),
            amount: (move: CheckingAccountMove) => (
              <td className="text-right">{formatCurrency(move.amount)}</td>
            ),
            notes: (move: CheckingAccountMove) => <td>{move.notes}</td>,
            date: (move: CheckingAccountMove) => (
              <td className="text-right">
                {dateFormat(move.createdAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
          }}
          tableProps={{
            striped: true,
          }}
        />
      </CRow>
    </>
  );
};

export default CustomerChecking;
