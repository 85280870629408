import yup from "src/helpers/validation";
import { gql } from "@apollo/client";
import { Company } from "./companies";
import { Product } from "./products";

export const CategorySchema = yup.object().shape({
  companyId: yup.number().min(1).required(),
  name: yup.string().required(),
});

export type Supplier = {
  id: number;
  name: string;
  notes: string;
  companyId: number;
  company: Company;
  products: Product[];
};

export type CreateSupplier = Omit<Supplier, "id" | "company" | "products">;

export type UpdateSupplier = CreateSupplier;

export const GET_SUPPLIER = gql`
  query getSupplier($id: Int!) {
    data: supplier(id: $id) {
      id
      name
      notes
      company {
        id
        name
      }
      products {
        id
        deletedAt
      }
    }
  }
`;

export const LIST_SUPPLIERS = gql`
  query getSuppliers($filters: SupplierFilters) {
    data: suppliers(filters: $filters) {
      data {
        id
        name
        notes
        company {
          id
          name
        }
        products: productSuppliers {
          supplierId
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation createSupplier($companyId: Int!, $name: String!, $notes: String) {
    data: createSupplier(companyId: $companyId, name: $name, notes: $notes) {
      id
      name
      notes
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier(
    $id: Int!
    $companyId: Int!
    $name: String!
    $notes: String
  ) {
    data: updateSupplier(
      id: $id
      companyId: $companyId
      name: $name
      notes: $notes
    ) {
      id
      name
      notes
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation deleteSupplier($id: Int!) {
    data: deleteSupplier(id: $id) {
      id
    }
  }
`;

export const UPDATE_PRODUCTS = gql`
  mutation updateSupplierProductsConfig(
    $supplierId: Int!
    $config: ProductsConfigInput!
  ) {
    data: updateSupplierProductsConfig(
      supplierId: $supplierId
      config: $config
    ) {
      id
    }
  }
`;
