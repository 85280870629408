import {
  CCol,
  CCard,
  CCardBody,
  CRow,
  CSmartTable,
  CButton,
  CCardHeader,
  CCardFooter,
  CForm,
  CFormSelect,
  CTooltip,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "src/helpers/numbers";
import { Transaction } from "src/api/transactions";
import Api from "src/api";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { Balance, CashRegister } from "src/api/registers";
import { dateFormat } from "src/helpers/dates";
import { AppLoader } from "src/components/Loader/Loader";
import { findPaymentMethod } from "src/helpers/payments";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { logEvent } from "src/helpers/analytics";
import CIcon from "@coreui/icons-react";
import { cilChart } from "@coreui/icons";

const types = {
  AddMoney: "Añadir Dinero",
  WithdrawMoney: "Quitar Dinero",
  Sell: "Venta",
};

const RegisterSession = () => {
  const navigate = useNavigate();
  const params = useParams();
  const registerId = Number(params.registerId);
  const sessionId = Number(params.id);
  const [transactions, setTransactions] = useState<Transaction[]>();

  if (!registerId || !sessionId) {
    navigate("/");
  }

  const { data: register } = useQuery<GraphQLFind<CashRegister>>(
    Api.Registers.GET_REGISTER,
    {
      variables: {
        id: registerId,
      },
    }
  );

  useQuery<GraphQLMeta<Transaction>>(Api.Transactions.LIST_TRANSACTIONS, {
    variables: {
      filters: {
        trashed: true,
        limit: 0,
        registerId,
        sessionId,
      },
    },
    onCompleted: ({ data }) => {
      setTransactions(data.data);
    },
  });

  const { data: balance } = useQuery<GraphQLFind<Balance[]>>(
    Api.Registers.GET_BALANCE,
    {
      variables: {
        registerId,
        sessionId,
      },
    }
  );

  const [mutation, { loading }] = useMutation(Api.Registers.CLOSE_REGISTER);

  const downloadReport = async () => {
    try {
      logEvent("session.report.download", {
        registerId,
        sessionId,
      });

      const response = await Api.Registers.downloadReport(
        registerId,
        sessionId
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.fileName);
      document.body.appendChild(link);
      link.click();

      logEvent("session.report.downloaded", {
        registerId,
        sessionId,
        filename: response.fileName,
      });

      link.remove();
    } catch (e: any) {
      logEvent("session.report.download.error", {
        registerId,
        sessionId,
        error: e.message,
      });
    }
  };

  const session = register?.data?.sessions?.find(
    (sess) => sess.id === sessionId
  );

  const formik = useFormik({
    initialValues: {
      search: "All",
    },
    onSubmit: () => {},
  });

  const filteredTransactions = useMemo(() => {
    if (!transactions || transactions.length === 0) {
      return [];
    }

    if (formik.values.search === "All") {
      return transactions;
    }

    return transactions.filter((tr) => tr.type === formik.values.search);
  }, [transactions, formik.values.search]);

  if (!filteredTransactions || !register?.data || !balance?.data || !session) {
    return <AppLoader />;
  }

  const closeCash = () => {
    if (!loading) {
      mutation({
        variables: {
          registerId,
          sessionId,
        },
      });
    }
  };

  return (
    <CCol lg={12}>
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol md="9" className="align-items-center d-flex">
              <span>
                Transacciones de la sesión abierta por{" "}
                <b>
                  {session?.user.name} {session?.user.lastname}
                </b>{" "}
                el día{" "}
                <b>{dateFormat(new Date(session.openedAt), "dd/MM/yyyy")}</b> a
                las <b>{dateFormat(new Date(session.openedAt), "HH:mm")}</b> hs.
              </span>
            </CCol>
            <CCol className="d-flex justify-content-end" md="3">
              {session.closedAt === null && transactions?.length === 0 && (
                <CButton
                  size="sm"
                  onClick={closeCash}
                  color="info"
                  className="mr-2"
                >
                  Cerrar caja
                </CButton>
              )}
              <Link
                to={`/sales?search=${
                  register.data.store.name
                }&date=${dateFormat(session.openedAt)}`}
                className="p-0"
              >
                <CButton size="sm" color="success">
                  Ventas del Día
                </CButton>
              </Link>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CForm onSubmit={formik.handleSubmit} className="mb-3">
            <CRow className="align-items-center d-flex justify-content-center">
              <CCol
                sm={12}
                className="row justify-content-end"
                style={{ flexWrap: "nowrap" }}
              >
                <CFormSelect
                  name="search"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.search}
                  style={{ flex: 1 }}
                >
                  <option value="All">Todos</option>
                  <option value="Sell">Venta</option>
                  <option value="WithdrawMoney">Quitar Dinero</option>
                  <option value="AddMoney">Añadir Dinero</option>
                </CFormSelect>

                <CTooltip content={<span>Descargar Reporte</span>}>
                  <CButton
                    color="primary"
                    type="button"
                    onClick={downloadReport}
                    className="ml-2 p-0 w-10 h-10"
                  >
                    <CIcon icon={cilChart} style={{ margin: "0 auto" }} />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>
          </CForm>

          <CRow>
            <CCol md="12">
              <CSmartTable
                itemsPerPage={filteredTransactions.length}
                items={
                  filteredTransactions.map((transaction) => ({
                    ...transaction,
                    _props: {
                      color: transaction.deletedAt ? "danger" : "default",
                    },
                  })) || []
                }
                columns={[
                  {
                    key: "type",
                    label: "Tipo",
                  },
                  {
                    key: "user",
                    label: "Empleado",
                  },
                  {
                    key: "description",
                    label: "Descripción",
                  },
                  {
                    key: "createdAt",
                    label: "Fecha",
                  },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  user: (item: Transaction) => (
                    <td>
                      <span>
                        {item.user.name} {item.user.lastname}
                      </span>
                    </td>
                  ),
                  type: (item: Transaction) => (
                    <td>
                      <span>{types[item.type]}</span>
                    </td>
                  ),
                  createdAt: (item: Transaction) => (
                    <td>
                      <span>
                        {dateFormat(
                          new Date(item.createdAt),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </span>
                    </td>
                  ),
                  amount: (item: Transaction) => (
                    <td className="text-right">
                      {formatCurrency(item.amount)}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="flex row justify-content-between">
            {balance.data.map((item, index) => {
              return (
                <CCol key={index} className="d-flex justify-content-center">
                  {findPaymentMethod(item.type)?.name}:{" "}
                  {formatCurrency(item.amount)}
                </CCol>
              );
            })}
            <CCol className="d-flex justify-content-center">
              Total:{" "}
              {formatCurrency(balance.data.reduce((a, b) => a + b.amount, 0))}
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  );
};

export default RegisterSession;
