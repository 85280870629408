import { useMutation } from "@apollo/client";
import { cilBadge, cilImagePlus, cilStar, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CImage,
  CForm,
  CRow,
  CCol,
  CButton,
  CTooltip,
} from "@coreui/react-pro";
import { useRef, useState } from "react";
import Api from "src/api";
import { Product } from "src/api/products";
import { Loader } from "src/components/Loader/Loader";

const ProductImages = ({
  product,
  refetch,
}: {
  product: Product;
  refetch: () => void;
}) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.Products.DELETE_IMAGE,
    {
      onCompleted: () => refetch(),
    }
  );
  const [markDefaultMutation, { loading: marking }] = useMutation(
    Api.Products.MARK_DEFAULT_IMAGE,
    {
      onCompleted: () => refetch(),
    }
  );

  const importImage = async () => {
    if (imageRef.current?.files?.length && !loading) {
      const image = imageRef.current.files[0];

      setLoading(true);

      const formData = new FormData();

      formData.append("file", image);
      formData.append("label", product.name);
      formData.append("description", product.description ?? "");

      try {
        await Api.Products.uploadImage(product.id, formData);

        refetch();
      } catch (e) {
        //
      } finally {
        if (imageRef.current) {
          imageRef.current.value = "";
        }

        setLoading(false);
      }
    }
  };

  const isLoading = loading || deleting || marking;

  return (
    <CCard className="mt-3">
      <CForm encType="multipart/form-data">
        <CCardHeader>Imagenes de Producto</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol className="d-flex gap-2">
              {product.photos?.map((photo) => (
                <div key={photo.id} className="position-relative">
                  {!photo.default ? (
                    <CTooltip
                      content={<span>Marcar imagen como destacada</span>}
                    >
                      <CButton
                        type="button"
                        color="info"
                        disabled={isLoading}
                        size="sm"
                        className="position-absolute"
                        style={{ right: 46, top: 6 }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "¿Estás seguro que quieres usar esta imagen como destacada?"
                            )
                          ) {
                            markDefaultMutation({
                              variables: {
                                productId: product.id,
                                imageId: photo.id,
                              },
                            });
                          }
                        }}
                      >
                        <CIcon icon={cilStar} />
                      </CButton>
                    </CTooltip>
                  ) : (
                    <div
                      className="position-absolute d-flex justify-content-center align-items-center"
                      style={{
                        top: 6,
                        left: 6,
                        height: 30,
                        width: 30,
                        backgroundColor: "rgba(0,0,0,0.5)",
                      }}
                    >
                      <CIcon
                        icon={cilBadge}
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  )}

                  <CTooltip content={<span>Eliminar imagen</span>}>
                    <CButton
                      type="button"
                      color="danger"
                      disabled={isLoading}
                      size="sm"
                      className="position-absolute"
                      style={{ right: 6, top: 6 }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres eliminar esta imagen?"
                          )
                        ) {
                          deleteMutation({
                            variables: {
                              productId: product.id,
                              imageId: photo.id,
                            },
                          });
                        }
                      }}
                    >
                      <CIcon icon={cilTrash} />
                    </CButton>
                  </CTooltip>

                  <CImage thumbnail src={photo.url} width={156} height={156} />
                </div>
              ))}

              <CButton
                type="button"
                variant="ghost"
                disabled={isLoading}
                className="p-0 ml-3"
                onClick={() => imageRef.current?.click()}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <CTooltip content={<span>Cargar nueva imagen</span>}>
                    <CIcon icon={cilImagePlus} size="3xl" />
                  </CTooltip>
                )}
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>

        <input
          type="file"
          style={{ display: "none" }}
          ref={imageRef}
          onChange={importImage}
          accept=".jpeg,.jpg,.png"
        />
      </CForm>
    </CCard>
  );
};

export default ProductImages;
