import { useRef, useState } from "react";
import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CAlert,
  CModal,
  CModalHeader,
  CModalFooter,
  CSmartTable,
  CCardHeader,
  CLoadingButton,
  CTooltip,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import {
  CashRegister,
  CashRegisterSession,
  CashRegisterUpdate,
} from "src/api/registers";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "src/helpers/numbers";
import { useFormik } from "formik";
import { isEmpty } from "lodash";

import Api from "src/api";
import { GraphQLFind } from "src/types";
import { getValidity } from "src/helpers/validation";
import { dateFormat } from "src/helpers/dates";
import { useAdminStore } from "src/store";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";
import { useCanNavigate } from "src/helpers/permissions";
import { StoreType } from "src/api/stores";
import CIcon from "@coreui/icons-react";
import { cilCash, cilChart, cilCloudUpload } from "@coreui/icons";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { getPaginationProps } from "src/hooks/pagination";

const LoadSaleButton = ({
  sessionId,
  registerId,
}: {
  sessionId: number;
  registerId: number;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toastRef = useRef<ForwardedToastProps>(null);
  const [mutation, { loading }] = useMutation(Api.Sales.CREATE_SALE, {
    onCompleted: () => {
      toastRef.current?.show("Venta cargada correctamente");

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
    onError: () => {
      toastRef.current?.show("Error al cargar la venta");

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
  });

  const importSale = () => {
    if (fileInputRef.current?.value !== "") {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          const obj = JSON.parse(event.target.result.toString());

          if (obj.sessionId && obj.sessionId !== sessionId) {
            return toastRef.current?.show(
              "La venta no corresponde a la sesion"
            );
          }

          if (obj.registerId !== registerId) {
            return toastRef.current?.show(
              "La venta no corresponde a la caja registradora"
            );
          }

          mutation({
            variables: {
              input: obj,
            },
          });
        }
      };

      if (fileInputRef.current?.files?.[0]) {
        reader.readAsText(fileInputRef.current.files[0]);
      }
    }
  };

  return (
    <>
      <CButton
        size="sm"
        onClick={() => {
          fileInputRef.current?.click();
        }}
        disabled={loading}
        className="mr-2"
        color="info"
        type="button"
      >
        <CIcon icon={cilCloudUpload} />
      </CButton>

      <Toast color="primary" autohide visible={false} ref={toastRef} text="" />

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={importSale}
        accept=".json"
      />
    </>
  );
};

const EditRegister = () => {
  const params = useParams();
  const navigate = useNavigate();
  const registerId = Number(params.id);
  const { user } = useAdminStore();

  if (!registerId) {
    navigate("/");
  }

  const { data: cashRegister } = useQuery<GraphQLFind<CashRegister>>(
    Api.Registers.GET_REGISTER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: registerId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  const downloadReport = async (sessionId: number) => {
    try {
      logEvent("session.report.download", {
        registerId,
        sessionId,
      });

      const response = await Api.Registers.downloadReport(
        registerId,
        sessionId
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.fileName);
      document.body.appendChild(link);
      link.click();

      logEvent("session.report.downloaded", {
        registerId,
        sessionId,
        filename: response.fileName,
      });

      link.remove();
    } catch (e: any) {
      logEvent("session.report.download.error", {
        registerId,
        sessionId,
        error: e.message,
      });
    }
  };

  if (!cashRegister?.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form register={cashRegister.data} />

      <CCard className="mt-3">
        <CCardHeader>Sesiones</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="12">
              <CSmartTable
                itemsPerPage={20}
                items={cashRegister?.data.sessions ?? []}
                {...getPaginationProps(cashRegister?.data.sessions ?? [])}
                columns={[
                  {
                    key: "id",
                    label: "ID",
                  },
                  {
                    key: "status",
                    label: "Estado",
                  },
                  {
                    key: "openedBy",
                    label: "Abierta por",
                  },
                  {
                    key: "openedAt",
                    label: "Abierta a las",
                  },
                  {
                    key: "closedAt",
                    label: "Cerrada a las",
                  },
                  {
                    key: "initialAmount",
                    label: "Monto inicial",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "actions",
                    label: "Acciones",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  openedBy: (item: CashRegisterSession) => (
                    <td>
                      <span>
                        {item.user.name} {item.user.lastname}
                      </span>
                    </td>
                  ),
                  status: (item: CashRegisterSession) => (
                    <td>
                      <span>{!item.closedAt ? "Abierta" : "Cerrada"}</span>
                    </td>
                  ),
                  openedAt: (item: CashRegisterSession) => (
                    <td>
                      <span>
                        {dateFormat(
                          new Date(item.openedAt),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </span>
                    </td>
                  ),
                  closedAt: (item: CashRegisterSession) => (
                    <td>
                      <span>
                        {item.closedAt
                          ? dateFormat(
                              new Date(item.closedAt),
                              "dd/MM/yyyy HH:mm"
                            )
                          : "-"}
                      </span>
                    </td>
                  ),
                  actions: (item: CashRegisterSession) => (
                    <td className="text-right">
                      {user?.isAdmin && (
                        <LoadSaleButton
                          registerId={cashRegister.data.id}
                          sessionId={item.id}
                        />
                      )}

                      <CTooltip content={<span>Ver detalle</span>}>
                        <CButton
                          size="sm"
                          color="success"
                          className="mr-2"
                          onClick={() => {
                            navigate(
                              `/registers/${registerId}/sessions/${item.id}`
                            );
                          }}
                          type="button"
                        >
                          <CIcon icon={cilCash} />
                        </CButton>
                      </CTooltip>

                      <CTooltip content={<span>Descargar Reporte</span>}>
                        <CButton
                          color="primary"
                          size="sm"
                          className="mr-2"
                          onClick={() => {
                            downloadReport(item.id);
                          }}
                        >
                          <CIcon icon={cilChart} />
                        </CButton>
                      </CTooltip>
                    </td>
                  ),
                  initialAmount: (item: CashRegisterSession) => (
                    <td className="text-right">
                      {formatCurrency(item.initialAmount)}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

const Form = ({ register }: { register: CashRegister }) => {
  const navigate = useNavigate();
  const { hasPermission } = useAdminStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const canEditRegister = hasPermission("UPDATE_REGISTER");
  const canDeleteRegister =
    hasPermission("DELETE_REGISTER") && register.store.type === StoreType.Store;

  useCanNavigate("SHOW_REGISTER");

  const [updateMutation, { loading: updLoading }] = useMutation(
    Api.Registers.UPDATE_REGISTER,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [deleteMutation, { loading: delLoading }] = useMutation(
    Api.Registers.DELETE_REGISTER,
    {
      onCompleted: () => {
        navigate("/registers");
      },
    }
  );

  const onDelete = () => {
    if (!delLoading) {
      if (register?.session) {
        alert("Una caja abierta no puede ser eliminada");
        setShowModal(false);
        return;
      }

      deleteMutation({
        variables: {
          id: register.id,
        },
      });
    }
  };

  const formik = useFormik<CashRegisterUpdate>({
    initialValues: register,
    onSubmit: (data) => {
      if (!updLoading) {
        updateMutation({
          variables: {
            id: register.id,
            name: data.name ?? register.name,
            description: data.description ?? register.description,
          },
        });
      }
    },
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>Caja Registradora - {register.name}</CCardHeader>
        <CCardBody>
          <CRow className="form-row">
            <CCol md="3" className="table-center">
              <CFormLabel>Punto de Venta</CFormLabel>
            </CCol>
            <CCol xs="12" md="9" className="table-center">
              <p>{register.store.name}</p>
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3" className="table-center">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                readOnly={!canEditRegister}
                defaultValue={formik.values.name}
                id="name"
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3" className="table-center">
              <CFormLabel htmlFor="description">Descripción</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                readOnly={!canEditRegister}
                defaultValue={formik.values.description ?? ""}
                id="description"
                placeholder="Descripción"
                name="description"
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.description,
                  formik.errors.description
                )}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3" className="table-center">
              <CFormLabel>Estado</CFormLabel>
            </CCol>
            <CCol xs="12" md="9" className="table-center">
              <p>{register.session?.openedAt ? "Abierta" : "Cerrada"}</p>
            </CCol>
          </CRow>
          {register.session?.openedAt && (
            <CRow className="form-row">
              <CCol md="3" className="table-center">
                <CFormLabel>Apertura</CFormLabel>
              </CCol>
              <CCol xs="12" md="9" className="table-center">
                <p>
                  {dateFormat(
                    new Date(register.session.openedAt),
                    "dd/MM/yyyy HH:mm"
                  )}
                </p>
              </CCol>
            </CRow>
          )}
        </CCardBody>
        {(canDeleteRegister || canEditRegister) && (
          <CCardFooter>
            <CRow className="px-0 align-items-center">
              <CCol md="8">
                {!isEmpty(formik.errors) && (
                  <CAlert color="danger" className="my-0">
                    Ha ocurrido un error. Vuelve a intentar
                  </CAlert>
                )}
              </CCol>
              <CCol md="4" className="flex justify-content-end">
                {canDeleteRegister && (
                  <>
                    <CButton
                      type="button"
                      color="danger"
                      size="sm"
                      className="mr-2"
                      onClick={() => setShowModal(true)}
                    >
                      Eliminar
                    </CButton>

                    <CModal
                      alignment="center"
                      visible={showModal}
                      onClose={() => setShowModal(false)}
                    >
                      <CModalHeader closeButton>
                        {register.session?.openedAt
                          ? "No puedes eliminar una caja que se encuentra abierta"
                          : "¿Estás seguro de eliminar este Punto de Venta?"}
                      </CModalHeader>
                      <CModalFooter>
                        <CButton
                          size="sm"
                          color="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Cancelar
                        </CButton>
                        <CButton
                          size="sm"
                          disabled={!!register.session?.openedAt}
                          color="danger"
                          onClick={onDelete}
                        >
                          Si, quiero eliminarlo
                        </CButton>
                      </CModalFooter>
                    </CModal>
                  </>
                )}
                {canEditRegister && (
                  <CLoadingButton
                    loading={updLoading}
                    disabled={updLoading}
                    color="primary"
                    size="sm"
                    type="submit"
                  >
                    Guardar
                  </CLoadingButton>
                )}
              </CCol>
            </CRow>
          </CCardFooter>
        )}
      </CCard>
    </CForm>
  );
};

export default EditRegister;
