import { ShipmentItem, ShipmentStatus } from "src/api/shipments";

export const parseShipmentStatus = (status: ShipmentStatus) => {
  switch (status) {
    case ShipmentStatus.Cancelled:
      return "Cancelado";

    case ShipmentStatus.Closed:
      return "Cerrado";

    case ShipmentStatus.Confirmed:
      return "Confirmado";

    case ShipmentStatus.Delivered:
      return "Recibido";

    case ShipmentStatus.Rejected:
      return "Rechazado";

    case ShipmentStatus.Returned:
      return "Devuelto";

    case ShipmentStatus.Sent:
      return "Enviado";

    case ShipmentStatus.Shipped:
      return "En Reparto";

    case ShipmentStatus.Open:
    default:
      return "Abierto";
  }
};

export const getCurrentQuantity = (
  item: ShipmentItem,
  status: ShipmentStatus
) => {
  if (
    [
      ShipmentStatus.Open,
      ShipmentStatus.Closed,
      ShipmentStatus.Sent,
      ShipmentStatus.Cancelled,
    ].includes(status)
  ) {
    return item.orderedQuantity;
  }

  if (
    [
      ShipmentStatus.Confirmed,
      ShipmentStatus.Shipped,
      ShipmentStatus.Rejected,
      ShipmentStatus.Returned,
    ].includes(status)
  ) {
    return item.confirmedQuantity;
  }

  return item.deliveredQuantity;
};

export const getCurrentTotal = (item: ShipmentItem, status: ShipmentStatus) => {
  if (
    [
      ShipmentStatus.Open,
      ShipmentStatus.Closed,
      ShipmentStatus.Sent,
      ShipmentStatus.Cancelled,
    ].includes(status)
  ) {
    return item.orderedQuantity * item.orderedPrice;
  }

  if (
    [
      ShipmentStatus.Confirmed,
      ShipmentStatus.Shipped,
      ShipmentStatus.Rejected,
      ShipmentStatus.Returned,
    ].includes(status)
  ) {
    return item.confirmedQuantity * item.confirmedPrice;
  }

  return item.deliveredQuantity * item.deliveredPrice;
};
